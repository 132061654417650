.e5 .row > * {
  padding: 0;
}

.e5 .row {
  display: block;
}

.e5 .navbar-expand-xl {
  flex-wrap: wrap;
}

.e5 .navbar {
  display: block;
}

.e5 .nav.navbar-nav {
  display: block;
}

.e5 .navbar-inverse .navbar-nav > li > a {
  display: block;
}

.e5 .navbar-expand-xl .navbar-collapse {
  display: block !important;
}

.e5 a,
.e5 a:hover,
.e5 a:active .e5 a:focus {
  text-decoration: none;
  color: rgb(35, 82, 124);
}

.e5 .navbar-text {
  color: rgb(157, 157, 157)
}

.e5 .nav.navbar-nav > li {
  line-height: 20px;
}

.e5 .navbar-collapse.collapse {
  max-height: calc(100vh - 120px);
}
