/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.css";
/*@import "~quill/dist/quill.bubble.css";*/
/*@import "~quill/dist/quill.snow.css";*/
/*@import "https://stackpath.bootstrapcdn.com/bootswatch/4.5.2/darkly/bootstrap.min.css";*/

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/material';

.tox.tox-tinymce .tox-toolbar__primary .tox-toolbar__group .tox-tbtn.tox-tbtn--select {
  /*width: 50px;*/
}

.tox.tox-tinymce .tox-toolbar__primary .tox-toolbar__group .tox-tbtn__select-label:before,
i.mce-i-[fa-images]:before {
  /*// FONT-AWESOME-CLASSNAME e.g. "icon-youtube" content: "[FONT-AWESOME-CONTENT]";*/
  /*// FONT-AWESOME-CONTENT e.g. */
  /*  content: "\f03e";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #000;
  font-size: 1.5em;
  padding-right: 0.5em;*/
  /*  position: absolute;
  top: 15%;
  left: 0;*/
}

button,
.e5 .mat-button,
.e5 .b-ctl-btn {
  background-color: #777;
  color: #efefef;
  border: 0;
  /*font-weight: bold;*/
  height: 34px;
  /*margin-left: 6px;*/
  margin-right: 6px;
  padding-left: 6px;
  padding-right: 6px;
}

.e5 .b-ctl-btn {
  /*background-color: #777;
  color: #efefef;
  border: 0;
  font-weight: bold;
  height: 35px;*/
  width: 35px;
}

.e5 .b-ctl-btn:hover {
  /*background-color: #999;*/
  color: #fff;
  background-color: #2D447B;
}

button,
.e5 .mat-button {
  color: #333;
  background-color: rgb(191, 190, 189);
  text-decoration: none;
}

.mat-button.selected {
  font-weight: bold;
}

html,
body {
  height: 100%;
  font-size: 14px !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

ul {
  padding: 0;
}

.b-form-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 0;
  position: relative;
}

.b-form-container.nopadding {
  padding: 0;
}

.b-form-container.wrapper {
  padding: 0 0 12px 0;
}

.b-form-container.form-field {
  height: 3.5rem;
}

.b-form-container.in-one-row .mat-checkbox-layout {
  padding-top: 12px;
}

.b-form-container.in-one-row .glyphicon {
  padding-top: 16px;
  padding-left: 12px;
}

.b-form-container.calendar-time {
  width: 175px;
}

.mat-radio-button~.mat-radio-button {
  margin-left: 16px;
}

.b-field {
  margin: 10px 0;
}

.b-field>* {
  margin: 5px 0;
}

.b-field .mat-radio-button {
  margin: 0 24px 0 0;
}

.e5 p:first-child {
  padding-top: 16px;
}

.e5 .mat-column-edit {
  text-align: right;
}

/*
  -- ----------------------------------------------------
  EVISION5-Style
  -- ----------------------------------------------------
  */
.e5 body {
  font-family: "open_sans", sans-serif;
  overflow: hidden;
}

.e5 .component-container.mode-dashboard .component-content-container {
  padding: 0 0;
  overflow: auto;
}

.e5 .component-container {
  height: 100%;
  /*padding-left: 12px;
  padding-right: 12px;*/
}

.e5 .component-container .component-content-container-frame {
  /*padding: 0;*/
  /*height: 100%;
    position: relative;
    box-sizing: border-box;*/
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.e5 .component-container .component-content-container {
  padding: 12px 24px;
  height: 100%;
  overflow: scroll;
  position: relative;
  box-sizing: border-box;
}

.e5 .component-container .component-content-container.abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.e5 .component-container .component-content-container.y-only {
  overflow-y: scroll;
  overflow-x: hidden;
}

.e5 .component-container .component-content-container.no-scroll {
  overflow: hidden;
}

.e5 .component-container .component-content-container.frame {
  padding: 0;
}

.e5 .component-container .component-content-container-frame-wrapper {
  position: relative;
}

.e5 .component-container .component-content-sidebar {
  background-color: rgb(201, 200, 199);
}

/*  ----------------------------------------------
  Headings
*/
.e5 h1,
.e5 h2,
.e5 h3,
.e5 h4,
.e5 h5,
.e5 .h1,
.e5 .h2,
.e5 .h3,
.e5 .h4,
.e5 .h5 {
  color: #333;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "open_sans", sans-serif;
  /*text-transform: uppercase;*/
}

.e5 h1,
.e5 .h1 {
  font-size: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 18px;
  margin-bottom: 9px;
}

.e5 h2,
.e5 .h2 {
  margin-top: 15.5px;
  margin-bottom: 16px;
  padding-left: 0;
}

.e5 h2,
.e5 h3,
.e5 h4,
.e5 h5,
.e5 .h1,
.e5 .h2,
.e5 .h3,
.e5 .h4,
.e5 .h5 {
  font-size: 15px;
}

/*  ----------------------------------------------
  Navigation
*/
.main-nav {
  z-index: 999 !important;
}

.e5 .navbar-inverse .navbar-nav>li>a {
  color: rgb(201, 200, 199);
}

.e5 .navbar-inverse .navbar-nav>li>a:hover {
  color: #fff;
}

/* Layout */
.e5 .body-content {
  padding-left: 0;
  padding-right: 0;
}

/*  ----------------------------------------------
  Toolbar
*/
.e5 .mat-toolbar {
  background-color: rgb(201, 200, 199);
  padding-left: 24px;
  padding-right: 41px;
}

.e5 .mat-toolbar .mat-checkbox-layout {
  margin-bottom: 1px;
}

.mat-dialog-container .mat-toolbar {
  padding-right: 24px;
}


.e5 .mat-toolbar>.mat-toolbar-row {
  padding-left: 0;
  padding-right: 0;
}

.e5 .mat-toolbar>.mat-toolbar-row>span,
.e5 .mat-toolbar>span {
  font-size: 14px;
}

.e5 .mat-toolbar .mat-form-field {
  font-size: 14px;
}

/*  ----------------------------------------------
  TabBar
*/
.e5 .mat-tab-group.mat-primary .mat-ink-bar,
.e5 .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  /*background-color: rgb(217,39,46);*/
  background-color: #ff4081;
}

.e5 .mat-header-cell {
  font-size: 14px;
}

/*  ----------------------------------------------
  Button
*/
/*.e5 button:not(.glyphicon):hover {*/
.e5 button:hover {
  color: #2D447B;
}

.e5 button>span {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  /*height: 35px;*/
}

/*  .e5 button > span > .ng-fa-icon {
    height: 34px;
    line-height: 34px;
  }*/

/* ----------------------------------------------
  Calender
*/
.e5 .mat-calendar-body-selected {
  background-color: #ff4081;
}

/* ----------------------------------------------
  DropDown
*/
/* Underline */
.e5 .mat-form-field.mat-focused .mat-form-field-ripple {
  /*background-color: rgb(217,39,46);*/
  background-color: #ff4081;
}

/* Selected item*/
.e5 .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.e5 .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.e5 .mat-form-field.mat-focused .mat-form-field-label {
  /*color: rgb(217,39,46);*/
  color: #ff4081;
}


.e5 .mat-tab-group,
.e5 .mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}

/* ----------------------------------------------
  Dialog
*/
.e5 .mat-dialog-container {
  padding: 0;
}

.e5 .mat-dialog-container h1 {
  margin: 14px;
}

.e5 .mat-dialog-container .component-content-container {
  overflow: auto;
}

/* ----------------------------------------------
  File Drag'n'drop
*/

.e5 ngx-file-drop {
  display: inline-block;
  float: right;
  line-height: 35px;
  height: 35px;
  margin: 6px;
  /*color: rgb(217,39,46);*/
  color: #ff4081;
}

.e5 ngx-file-drop .ngx-file-drop__drop-zone {
  height: auto;
  font-style: italic;
  width: 100%;
  padding: 0 12px;
  border: none;
}

.e5 ngx-file-drop .ngx-file-drop__drop-zone .ngx-file-drop__content {
  height: auto;
  /*color: rgb(217,39,46);*/
  color: #ff4081;
}


.mat-list.textlist .mat-list-item .mat-list-item-content {
  padding: 0;
}

.mat-list.textlist .mat-list-item .mat-list-item-content .mat-button {
  padding: 0 6px;
}

.mat-checkbox-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
}

/* ----------------------------------------------
  TableCell
*/

table.mat-table td.mat-cell {
  /*padding: 0 12px;*/
  padding-right: 24px;
  text-align: left;
}

table.mat-table td.mat-cell.cell-right {
  text-align: right;
}

table.mat-table td.mat-cell.cell-buttons {}

/* 70px per button + 25px*/
table.mat-table td.mat-cell:first-of-type.cell-buttons.one-button {
  width: 119px;
  white-space: nowrap;
}

table.mat-table td.mat-cell:first-of-type.cell-buttons.two-buttons {
  width: 189px;
  white-space: nowrap;
}

td.mat-cell.cell-buttons.three-buttons,
td.mat-cell.cell-buttons.two-buttons {
  text-align: right;
  padding-right: 0px;
}

table.mat-table td.mat-cell:first-of-type.cell-buttons.three-buttons {
  width: 259px;
  white-space: nowrap;
}

/* 70px per button + 25px*/
table.mat-table td.mat-cell.cell-buttons.one-button {
  width: 95px;
  white-space: nowrap;
}

table.mat-table td.mat-cell.cell-buttons.two-buttons {
  width: 165px;
  white-space: nowrap;
}

table.mat-table td.mat-cell.cell-buttons.three-buttons {
  width: 235px;
  white-space: nowrap;
}

/* Typically the status is centered vertically but in random cases it does not work.
   Therefore the following is done to achieve this. */
table.mat-table .status-wrapper,
table.mat-table .button-wrapper {
  height: 100%;
  position: relative;
  width: 30px;
  display: block;
  float: right;
}

table.mat-table .status-wrapper.left,
table.mat-table .button-wrapper.left {
  float: left;
}

table.mat-table .status-wrapper.left .centered,
table.mat-table .button-wrapper.left .centered {
  right: auto;
  left: 0;
}

table.mat-table .status-wrapper .centered,
table.mat-table .button-wrapper .centered {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  white-space: nowrap;
  right: 0;
}


table.mat-table .status-wrapper .centered {
  height: 18px;
}

table.mat-table .button-wrapper .centered {
  height: 35px;
  line-height: 35px;
}

table.mat-table .status-wrapper .centered .glyphicon,
table.mat-table .button-wrapper .centered .glyphicon {
  padding: 0;
}

table.mat-table tr.selected td {
  background-color: rgba(45, 68, 123, 0.1);
  /*rgba(0,0,0,0.05);*/
  font-weight: bold;
  color: #2D447B;
}

/* ----------------------------------------------
  DatePicker
*/

.e5 .mat-datepicker-toggle {
  width: 24px;
  display: inline-block;
}

.e5 .mat-datepicker-toggle .mat-focus-indicator.mat-icon-button.mat-button-base {
  position: absolute;
  bottom: 0;
  width: 24px;
}

.e5 .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.e5 .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 24px;
}

/* ----------------------------------------------
  TimePicker
*/
.e5 .timepicker-backdrop-overlay {
  z-index: 1000 !important;
}

.e5 .timepicker-overlay {
  z-index: 1000 !important;
}

.e5 ngx-timepicker-field {
  padding-bottom: 1.25em;
  line-height: 1em;
  height: 1.71875em;
  /* 2.8em;*/
  position: absolute;
  bottom: 0;
  width: 170px;
}

/* Required to adjust color to ng-material-border-bottom-color. */
.e5 ngx-timepicker-field .ngx-timepicker {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker-control.ngx-timepicker-control--active:after {
  background-color: #ff4081;
  height: 2px;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--first,
.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--second,
.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--third,
.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__toggle {
  position: absolute;
  bottom: 0;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--first {
  left: 0;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--second {
  left: 60px;
  height: 30px;
  line-height: 30px;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__control--third {
  left: 74px;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__toggle {
  left: 134px
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__toggle .ngx-material-timepicker-toggle {
  height: auto;
}

.e5 ngx-timepicker-field .ngx-timepicker .ngx-timepicker__toggle .ngx-material-timepicker-toggle [ngxmaterialtimepickertoggleicon] {
  height: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.e5 .timepicker .clock-face__clock-hand {
  background-color: #2D447B;
}

.e5 .timepicker .clock-face__clock-hand:before {
  border-color: #2D447B;
}

.e5 .timepicker .timepicker__header {
  background-color: #2D447B;
}

.e5 .timepicker .clock-face__number>span.active {
  background-color: #2D447B !important;
}

.e5 .timepicker .timepicker-button {
  color: #2D447B;
}

.e5 .ngx-timepicker path {
  fill: rgba(0, 0, 0, .54);
}

/* ---------------------------------------------------------------------------- */
.mat-cell span.glyphicon {
  font-size: 18px;
  padding-top: 3px;
}

.mat-cell span.glyphicon.size-l {
  font-size: 26px;
  padding-top: 3px;
}

mat-slider {
  width: 300px;
}

.mat-slider-horizontal {
  width: 300px;
}

/* ---------------------------------------------------------------------------- */

.component {
  position: relative;
}

/* ---------------------------------------------------------------------------- */

.component .busy-indicator-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 99;
  display: none;
  cursor: progress;
}

.component .busy-indicator-frame.visible {
  display: block;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}

.component .busy-indicator-frame .busy-indicator-img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ---------------------------------------------------------------------------- */

.dashboard-card .mat-card-header-text {
  margin-left: 0;
}

/* Unfortunately cannot be set in dashboad.component.css even if this is specific for dashboard. */
.dashboard-card.datetime .mat-card-header-text {
  width: 100%;
  text-align: right;
}

/* ---------------------------------------------------------------------------- */

/* Set badge-color. */
.mat-badge-content {
  background: #2D447B;
}

/* ---------------------------------------------------------------------------- */

/* Adjustment of fontawesome for buttons. */
.e5 .mat-button .mat-button-wrapper .ng-fa-icon {
  display: block;
  font-size: 16px;
}

/* ---------------------------------------------------------------------------- */

/* Change padding-left for headings in dialog. */
.e5 .mat-dialog-container h1 {
  padding-left: 8px;
}


.navbar-collapse.collapse {
  overflow-y: scroll !important;
  max-height: 92vh;
}

.navbar-collapse.collapse.navmenu::-webkit-scrollbar {
  display: none;
}

.navbar-collapse.collapse.navmenu {
  overflow-y: auto !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cursor-pointer {
  cursor: pointer;
}

.e5 .CodeMirror {
  height: 100%;
}

.font-red {
  color: rgb(170, 0, 0);
}

.font-green {
  color: rgb(0, 170, 0);
}

.e5 .cell-200 {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
}
